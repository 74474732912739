import create from "zustand";

import { ApiError } from "../../../../apiBindings";
import { iLinks, iMeta } from "../DynPin/components/Pagination";
import { BaseUser, getUsersByCustomer } from "./api";

interface CustomerUsersStore {
    customerUsers: BaseUser[];
    loading: boolean;
    error: string | null;
    paginationMeta: iMeta;
    paginationLinks: iLinks;
    setLoading: (state: boolean) => void;
    fetchCustomerUsers: (
        customerNumber: number,
        page?: number,
        search?: string | null
    ) => Promise<BaseUser[]>;
}

const useCustomerUsersStore = create<CustomerUsersStore>((set) => ({
    customerUsers: [],
    loading: false,
    error: null,
    paginationLinks: {
        first: "",
        last: "",
        next: null,
        prev: null,
    },
    paginationMeta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 20,
        to: 1,
        total: 0,
        links: [],
    },

    setLoading: (state) => {
        set({ loading: state });
    },
    fetchCustomerUsers: async (
        customerNumber: number,
        page = 1,
        search = null
    ) => {
        set({ loading: true, error: null });
        try {
            const response = await getUsersByCustomer(
                customerNumber,
                page,
                search
            );
            set({
                customerUsers: response.data,
                paginationMeta: response.meta,
                paginationLinks: response.links,
            });
            return response.data;
        } catch (error) {
            set({
                error:
                    error instanceof ApiError
                        ? error.message
                        : "Es ist ein unerwarteter Fehler aufgetreten.",
            });
            throw new Error("Failed to fetch users."); // Ensure a consistent error message
        } finally {
            set({ loading: false });
        }
    },
}));

export default useCustomerUsersStore;
