import styled from "@emotion/styled";

interface FadeInDivProps {
    duration?: string;
}

export default styled.div<FadeInDivProps>`
    animation: fade-in ${(props) => props.duration || "0.3s"} ease-in;
    @keyframes fade-in {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`;
