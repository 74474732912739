import {
    ApiResponse,
    errorAwareApiFetchProtected,
} from "../../../../apiBindings";
import { iLinks, iMeta } from "../DynPin/components/Pagination";

export interface BaseUser {
    id: number;
    keycloak_id: string;
    name: string;
    email: string;
    email_verified_at: string;
    created_at: string;
    updated_at: string;
}

export type CustomerUserResponse = {
    data: BaseUser[];
    links: iLinks;
    meta: iMeta;
};

export type ResponseWithMessage = {
    message: string;
};

export type CustomerUserRole = {
    id: number;
    name: string;
    display_name: string;
    hierarchy_index: number;
    customer_number: number | null;
    guard_name: string;
    description: string;
    created_at: string;
    updated_at: string;
};

export type CustomerUserRoleResponse = {
    data: CustomerUserRole[];
};

export const getUsersByCustomer = (
    customerNumber: number,
    page: number,
    search: string | null = null
) => {
    let url = `/hoyercard-portal/customer/${customerNumber}/user?page=${page}`;
    if (search) {
        url += `&search=${search}`;
    }
    return errorAwareApiFetchProtected<CustomerUserResponse>(url, {
        method: "GET",
    });
};

export const addUserToCustomer = async (
    customerNumber: number,
    email: string,
    role_id: string
): Promise<ApiResponse<ResponseWithMessage>> => {
    return await errorAwareApiFetchProtected<ResponseWithMessage>(
        `/hoyercard-portal/customer/${customerNumber}/user`,
        {
            body: JSON.stringify({ email, role_id }),
            method: "PUT",
        }
    );
};

export const removeUserFromCustomer = (
    customerNumber: number,
    userId: number
) => {
    return errorAwareApiFetchProtected<ResponseWithMessage>(
        `/hoyercard-portal/customer/${customerNumber}/user`,
        {
            body: JSON.stringify({ user_id: userId }),
            method: "DELETE",
        }
    );
};

export const getAvailableRoles = (): Promise<CustomerUserRoleResponse> => {
    return errorAwareApiFetchProtected<CustomerUserRoleResponse>(
        `/hoyercard-portal/customer/available-roles`,
        {
            method: "GET",
        }
    );
};
