import React from "react";

import { useTheme } from "@emotion/react";

import { IconPredefined } from "../../../../shared/icons/Icon";
import { PredefinedIconType } from "../../../../shared/icons/Icon/Predefined";
import { Table, TBody, Td, Th, THead } from "../../Dashboard/parts/CardsUser";
import Pagination, { iLinks, iMeta } from "../../DynPin/components/Pagination";
import { BaseUser } from "../api";

interface CustomerUsersTableProps {
    customerUsers: BaseUser[] | null;
    loading: boolean;
    error?: string | null;
    confirmDetach: (user: BaseUser) => void;
    canDetachUser: (user: BaseUser) => boolean;
    paginationLinks?: iLinks;
    paginationMeta?: iMeta;
    onPageChange: (page: number) => void;
}

const CustomerUsersTable: React.FC<CustomerUsersTableProps> = ({
    customerUsers,
    loading,
    confirmDetach,
    canDetachUser,
    paginationLinks,
    paginationMeta,
    onPageChange,
}) => {
    const theme = useTheme();

    if (loading) {
        return <div>Daten werden geladen...</div>;
    }

    return (
        <>
            <Table>
                <THead>
                    <tr>
                        <Th>Name</Th>
                        <Th>E-Mail</Th>
                        <Th></Th>
                    </tr>
                </THead>
                <TBody>
                    {!customerUsers || customerUsers.length === 0 ? (
                        <tr>
                            <Td colSpan={3}>Keine Daten gefunden...</Td>
                        </tr>
                    ) : (
                        customerUsers.map((user) => (
                            <tr key={user.id}>
                                <Td>{user.name}</Td>
                                <Td>{user.email}</Td>
                                <Td>
                                    {canDetachUser(user) && (
                                        <IconPredefined
                                            type={PredefinedIconType.dustbin}
                                            cursor="pointer"
                                            size="24px"
                                            sExtraSmall="24px"
                                            title="Verknüpfung aufheben"
                                            color={theme.colors.blue.toString()}
                                            hoverColor={theme.colors.red.toString()}
                                            onClick={() => confirmDetach(user)}
                                        />
                                    )}
                                </Td>
                            </tr>
                        ))
                    )}
                </TBody>
            </Table>
            {paginationLinks && paginationMeta && (
                <Pagination
                    paginationLinks={paginationLinks}
                    paginationMeta={paginationMeta}
                    setPage={onPageChange}
                />
            )}
        </>
    );
};

export default CustomerUsersTable;
