import React, { useState } from "react";

import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import FadeInAnimation from "../../../../../animations/fadeInAnimation";
import Headline from "../../../../base/atoms/Headline";
import NewInput from "../../../../shared/forms/NewInput";
import NewSelect from "../../../../shared/forms/NewSelect";
import { IconPredefined } from "../../../../shared/icons/Icon";
import { PredefinedIconType } from "../../../../shared/icons/Icon/Predefined";
import Button from "../../../../shared/NewForms/Button";
import {
    Backdrop,
    CloseButton,
    Content,
    Header,
    HeaderContent,
    PopUpContainer,
} from "../../../../shared/NewsletterModal";
import { CustomerUserRole } from "../api";

const ActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
`;

const Error = styled.small`
    color: ${({ theme }) => theme.colors.red.toString()};
`;

interface AddUserModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (email: string, userId: string) => void;
    loading: boolean;
    availableRoles: CustomerUserRole[];
}

const AddUserModal: React.FC<AddUserModalProps> = ({
    isOpen,
    onClose,
    onSubmit,
    loading,
    availableRoles,
}) => {
    const theme = useTheme();
    const [error, setError] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [roleId, setRoleId] = useState<string>(
        availableRoles[0]?.id.toString() ?? ""
    );

    if (!isOpen) return null;

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setError("");
        if (!email) {
            return setError("Bitte wähle eine E-Mail aus.");
        }
        if (!roleId) {
            return setError("Bitte wähle eine Rolle aus.");
        }
        onSubmit(email, roleId);
        setEmail("");
    };

    const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <FadeInAnimation duration="0.5s">
            <Backdrop
                onClick={handleOverlayClick}
                role="dialog"
                aria-modal="true"
                aria-labelledby="customer-user-modal"
            >
                <PopUpContainer role="document">
                    <Header>
                        <HeaderContent>
                            <Headline>Neuen Benutzer einladen</Headline>
                            <CloseButton
                                aria-label="Modal Schließen"
                                onClick={onClose}
                            >
                                <IconPredefined
                                    color={theme.colors.blue.toString()}
                                    type={PredefinedIconType.close}
                                    size="28px"
                                />
                            </CloseButton>
                        </HeaderContent>
                    </Header>

                    <Content>
                        <p>
                            Bitte gib die E-Mail-Adresse des Nutzers ein, der
                            eingeladen werden soll. Sofern für dieser Nutzer
                            bereits über ein Hoyer Card Konto verfügt, werden
                            wir im Anschluss eine Einladung versenden.
                        </p>
                        <form onSubmit={handleSubmit}>
                            <NewSelect
                                onChange={(e) => setRoleId(e.target.value)}
                                placeholder="Rolle auswählen"
                                options={availableRoles.map((item) => {
                                    return {
                                        value: item.id.toString(),
                                        text: item.display_name,
                                    };
                                })}
                                value={roleId}
                            />
                            <NewInput
                                type="email"
                                placeholder="E-Mail-Adresse eingeben"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                disabled={loading}
                            />
                            {error && <Error>Fehler: {error}</Error>}
                            <ActionContainer>
                                <Button disabled={loading} type="submit">
                                    Einladung verschicken
                                </Button>
                            </ActionContainer>
                        </form>
                    </Content>
                </PopUpContainer>
            </Backdrop>
        </FadeInAnimation>
    );
};

export default AddUserModal;
